export const apiEndPoint = "https://api.pro.ecosense.io/api/v1";
export const accessUrl = "https://pro.ecosense.io";
export const awsXApiKey = "Vl8OXDnNwY6mLWmMArHgP9MuXZh723ATQ8LAhVj0";
export const showSingleMenu = true;

export const awsAmplifyAuth = {
  region: "us-west-2",
  userPoolId: "us-west-2_uARztWWjJ",
  identityPoolId: "us-west-2:44e7ec89-23a6-42b0-905d-3c494e27d9e7",
  userPoolWebClientId: "3dl7t20oesk6p6epiusig65djc",
};
